import {
  Configuration,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IStackProps,
  IStackStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  ThemeProvider,
  Toggle,
} from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { Client } from "@microsoft/microsoft-graph-client";
import {
  AuthCodeMSALBrowserAuthenticationProvider,
  AuthCodeMSALBrowserAuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { User } from "microsoft-graph";
import React, { useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { SubmitHandler, useForm } from "react-hook-form";
import "./index.css";
import { darkTheme, lightTheme } from "./themes";

initializeIcons();

const config: Configuration = {
  auth: {
    clientId: "e09410ed-cb93-463e-a26d-cb0d66f715be",
    authority:
      "https://login.microsoftonline.com/fad42abb-dfda-43f0-9120-b18e6e86169d",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const ErrorComponent = ({ error }: FallbackProps) => (
  <pre>{JSON.stringify(error, null, 2)}</pre>
);

export const App: React.FunctionComponent = () => {
  const instance = new PublicClientApplication(config);
  const [useDarkMode] = useState<boolean>(true);

  return (
    <ThemeProvider applyTo="body" theme={useDarkMode ? darkTheme : lightTheme}>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <MsalProvider instance={instance}>
          <AuthenticatedTemplate>
            <UserCreationForm />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <LogonPage />
          </UnauthenticatedTemplate>
        </MsalProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const LogonPage: React.FC = () => {
  const { login } = useMsalAuthentication(InteractionType.Redirect);
  return <PrimaryButton onClick={() => login()}>Login</PrimaryButton>;
};

const stackTokens = { childrenGap: 50 };
const stackStyles: Partial<IStackStyles> = { root: { width: 500 } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 500 } },
};

interface Inputs {
  lsi: string;
  domain: string;
  givenName: string;
  surname: string;
  forceChangePasswordNextSignIn: boolean;
}
// Mail domains
const domains = [
  "gchq.gov.uk",
  "nta.cpni.gov.uk",
  "ntac.gov.uk",
  "cnct.mod.gov.uk",
  "lsh.mod.gov.uk",
  "cpni.gov.uk",
];

const alpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numbers = "0123456789";
const symbols = "!@#$%^&*_-+=";

const generatePassword = (length: number, characters: string) => {
  let password = "";
  for (let i = 0; i < length; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return password;
};

interface IResult {
  upn?: string;
  password: string;
}

const UserCreationForm: React.FC = () => {
  const { instance, accounts } = useMsal();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const options: AuthCodeMSALBrowserAuthenticationProviderOptions = {
    account: accounts[0],
    interactionType: InteractionType.Redirect,
    scopes: ["User.ReadWrite.All"],
  };
  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    instance as PublicClientApplication,
    options
  );

  const graphClient = Client.initWithMiddleware({
    authProvider,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [result, setResult] = useState<IResult>();

  const onSubmit: SubmitHandler<Inputs> = ({
    lsi,
    givenName,
    domain = domains[0],
    surname,
    forceChangePasswordNextSignIn = false,
  }) => {
    givenName = givenName.charAt(0).toUpperCase() + givenName.slice(1);
    surname = surname.charAt(0).toUpperCase() + surname.slice(1);

    const password = generatePassword(12, alpha + numbers + symbols);

    const mailNickname = `${givenName.toLowerCase()}${lsi}`;
    const displayName = `${givenName} ${surname[0]}`;

    const user: User = {
      displayName,
      userPrincipalName: `${mailNickname}@${domain}`,
      mailNickname,
      accountEnabled: true,
      employeeId: lsi,
      officeLocation: lsi,
      givenName,
      surname,
      usageLocation: "GB",
      passwordProfile: {
        forceChangePasswordNextSignIn,
        forceChangePasswordNextSignInWithMfa: false,
        password,
      },
    };

    setError(undefined);
    setLoading(true);

    graphClient
      .api("/users")
      .post(user)
      .then((u: User) => {
        setLoading(false);
        setResult({ upn: u.userPrincipalName || undefined, password });
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const [domainKey, setSelectedDomainKey] = useState<string>(domains[0]);
  const onDomainChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    if (option) {
      setSelectedDomainKey(option.key as string);
      setValue("domain", option.text);
    }
  };

  const resetForm = () => {
    setSelectedDomainKey(domains[0]);
    setError(undefined);
    setResult(undefined);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ margin: "auto", width: 500 }}
    >
      <h2>User Account Creation</h2>

      <Stack horizontal tokens={stackTokens} styles={stackStyles}>
        <Stack {...columnProps}>
          <TextField
            label="Forename"
            required
            disabled={loading}
            errorMessage={
              errors.givenName?.type == "pattern"
                ? "Invalid value entered"
                : undefined
            }
            {...register("givenName", {
              required: true,
              pattern: /^[A-Za-z]{2,64}$/i,
            })}
          />

          <TextField
            label="Surname"
            required
            disabled={loading}
            errorMessage={
              errors.surname?.type == "pattern"
                ? "Invalid value entered"
                : undefined
            }
            {...register("surname", {
              required: true,
              pattern: /^[A-Za-z']{1,64}$/i,
            })}
          />

          <TextField
            label="Low Side Identifier (LSI)"
            required
            disabled={loading}
            errorMessage={
              errors.lsi?.type == "pattern"
                ? "LSI should be 5 numeric digits"
                : undefined
            }
            {...register("lsi", { required: true, pattern: /^\d{5}$/ })}
          />

          <Dropdown
            label="Mail Domain"
            required
            disabled={loading}
            selectedKey={domainKey}
            options={domains.map((d) => ({
              key: d,
              text: d,
            }))}
            onChange={onDomainChange}
          />

          <Toggle
            label="Must change password next sign in"
            disabled={loading}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              checked?: boolean
            ) => {
              if (checked) setValue("forceChangePasswordNextSignIn", checked);
            }}
          />
        </Stack>
      </Stack>

      {result ? (
        <MessageBar isMultiline messageBarType={MessageBarType.success}>
          <b>User was successfully created!</b>
          <dl>
            <dt>User Principal Name:</dt>
            <dd>
              <code>{result.upn}</code>
            </dd>
            <dt>Password:</dt>
            <dd>
              <code>{result.password}</code>
            </dd>
          </dl>
        </MessageBar>
      ) : undefined}

      {error ? (
        <MessageBar messageBarType={MessageBarType.error}>
          {error.name} {error.message}
        </MessageBar>
      ) : undefined}

      <Stack horizontal style={{ marginTop: 10 }} tokens={{ childrenGap: 10 }}>
        <DefaultButton onClick={() => resetForm()}>Reset Form</DefaultButton>
        <PrimaryButton disabled={loading} type="submit">
          Create User
          {loading ? (
            <Spinner size={SpinnerSize.small} style={{ marginLeft: 10 }} />
          ) : undefined}
        </PrimaryButton>
      </Stack>
    </form>
  );
};
