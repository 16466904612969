import { createTheme } from "@fluentui/style-utilities";

export const lightTheme = createTheme({
  palette: {
    themePrimary: "#9e036a",
    themeLighterAlt: "#fbf1f8",
    themeLighter: "#efcae3",
    themeLight: "#e2a0cc",
    themeTertiary: "#c5519e",
    themeSecondary: "#aa1779",
    themeDarkAlt: "#8e0360",
    themeDark: "#780251",
    themeDarker: "#59023c",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

export const darkTheme = createTheme({
  palette: {
    themePrimary: "#f404a4",
    themeLighterAlt: "#fff5fb",
    themeLighter: "#fdd6f0",
    themeLight: "#fcb2e3",
    themeTertiary: "#f967c8",
    themeSecondary: "#f622af",
    themeDarkAlt: "#dc0494",
    themeDark: "#ba047d",
    themeDarker: "#89035c",
    neutralLighterAlt: "#212121",
    neutralLighter: "#2a2a2a",
    neutralLight: "#393939",
    neutralQuaternaryAlt: "#424242",
    neutralQuaternary: "#494949",
    neutralTertiaryAlt: "#686868",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#181818",
  },
});
